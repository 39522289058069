import axios from "axios"
import Store from '@/store/index'

type pro = {
  Is_Bulk: string;
  id: string;
  image: string;
  onhand: string;
  title: string;
  unit_title: string;
  Min_Order: string;
  Max_Order: string;
  Lot_Size: string;
  Prod_Price: string | number;
  priceFormate: string;
  Prod_Gross_Price: string | number;
  priceGrossFormate: string;
  org_id: string;
}
export function viewblock (blockID: string, parentID: string) {
  axios.post('/api/Store/Blocks/products',{
    "block_id" : blockID
  }).then(Response => {
  Store.dispatch('load_page', false)
  if (Response.data.Result.error) {
    Store.dispatch('alertError', {show: true, type: 'network'})
    return
  }
  let products = [] as pro[]
  Store.dispatch('blockProduct', {products: Response.data.Result, parentID: parentID})
}).catch (() => {
  // Store.dispatch('alertError', {show: true, type: 'network'})
})
}
export function getblock () {
  Store.dispatch('load_page', true)
      axios.post('/api/app/home',{
      }).then(Response => {
        if (Response.data.Result.error) {
          Store.dispatch('alertError', {show: true, type: 'network'})
          return
        }
        const products = Response.data.Result.blocks
        Store.dispatch('blocks', products)
        Store.dispatch('blocksList', products[0].children)
        for (const list of products[0].children) {
          viewblock(list.id, list.id)
        }
      }).catch (() => {
        // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function blockList (parentID: string) {
  axios.post('/api/Store/Blocks/list',{
    "parent_id": parentID
  }).then(Response => {
    if (Response.data.Result.error) {
      Store.dispatch('alertError', {show: true, type: 'network'})
      return
    }
    const blocks = Response.data.Result
    Store.dispatch('blocksList', blocks)
    for (const list of blocks) {
      viewblock(list.id, list.id)
    }
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function galleries (gallerieID: string, state: string) {
  axios.post('/api/Social/Galleries/getGalleryItems',{
    gallery_id: gallerieID
  }).then(Response => {
    if (Response.data.Result.error) {
      Store.dispatch('alertError', {show: true, type: 'network'})
      return
    }
    Store.dispatch(state, Response.data.Result)
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}
