<template>
  <div>
    <error-alert />
    <mheader />
    <v-main class="pt-15 pb-5">
      <banner class="my-3" :option="option" />
      <blocks class="mt-6" />
      <brands class="mt-2 mb-10" :options="options" imageWidth="97" imageHeight="97" />
      <div>
        <action />
      </div>
    </v-main>
  </div>
</template>
<script>
import { galleries, getblock } from './models/home'
import { mapState } from 'vuex'
import { viewbasket_products } from '../Basket/models/Basket'
import store from '@/store'
export default {
  components: {
    action: () => import('./components/navbar/actionBar'),
    banner: () => import('./components/slider/banerSlider'),
    blocks: () => import('./components/slider/productSlider'),
    brands: () => import('./components/slider/brandsSlider.vue'),
    mheader: () => import('./components/navbar/headerSearchIcon'),
    ErrorAlert: () => import('@/components/alerts/error.alert.vue'),
  },
  computed: {
    ...mapState({
      loading_page: state => state.loading_page,
      blockProduct: state => state.home.blockProduct
    })
  },
  data: () => ({
    get_block: true,
    options: {
      rewind: true,
      trimSpace: true,
      width: '100%',
      perPage: 4,
      arrows: false,
      drag: true,
      pagination: false,
      waitForTransition: false,
      type: 'loop',
      perMove: 1,
      gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl',
      breakpoints: {
        280: {
          width: '100%',
          gap: '0.5rem',
          perPage: 2.2,
        },
        340: {
          width: '100%',
          gap: '0.5rem',
          perPage: 3.2,
        },
        499: {
          width: '100%',
          gap: '0.5rem',
          perPage: 3.2,
        },
        500: {
          width: '100%',
          gap: '0.5rem',
          perPage: 5,
        },
        540: {
          width: '100%',
          gap: '0.5rem',
          perPage: 5.2,
        },
        800: {
          width: '100%',
          gap: '0.5rem',
          perPage: 5,
        },
        1024: {
          width: '100%',
          gap: '0.5rem',
          perPage: 7.5,
        },
      },
    },
    option: {
      rewind: true,
      trimSpace: true,
      width: '100%',
      perPage: 1.5,
      arrows: false,
      drag: true,
      pagination: false,
      waitForTransition: false,
      type: 'loop',
      perMove: 1,
      gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl',
      breakpoints: {
        280: {
          width: '100%',
          perPage: 1,
        },
        340: {
          width: '100%',
          perPage: 1.2,
        },
        341: {
          width: '100%',
          perPage: 1.2,
        },
        499: {
          width: '100%',
          perPage: 1.2,
        },
        500: {
          width: '100%',
          perPage: 1.5,
        },
        540: {
          width: '100%',
          perPage: 1.3,
        },
        670: {
          width: '100%',
          perPage: 1.5,
        },
        671: {
          width: '100%',
          perPage: 1.5,
        },
        800: {
          width: '100%',
          perPage: 1.5,
        },
        801: {
          width: '100%',
          perPage: 1.5,
        },
        1024: {
          width: '100%',
          perPage: 2,
        }
      },
    },
  }),
  mounted() {
    let config = JSON.parse(localStorage.getItem('config'))
    localStorage.removeItem('filter_search')
    store.dispatch('productDetail')
    const basket = localStorage.getItem('basket')
    if (basket) {
      const time = new Date()
      const now = time.getTime()
      const old_time_basket = parseInt(localStorage.getItem('time_basket'))
      if ((old_time_basket + 86400000) < now) {
        localStorage.removeItem('basket')
        viewbasket_products()
      } else {
        store.dispatch('basket_local', JSON.parse(basket))
      }
    } else {
      viewbasket_products()
    }
    if (this.blockProduct.length < 1) {
      getblock()
      galleries(config.mobile_slider_gallery, 'banner')
      galleries(config.brands_gallery, 'brands')
    }
  }
}
</script>